const documentDirectory = "Documentos";
const semesterDirectory = "demonstracao-financeira.pdf";

export const financialStatements = [
  {
    year: 2023,
    semester1: `${documentDirectory}/2023/1S/${semesterDirectory}`,
    semester2: `${documentDirectory}/2023/2S/${semesterDirectory}`,
  },
  {
    year: 2022,
    semester1: `${documentDirectory}/2022/1S/${semesterDirectory}`,
    semester2: `${documentDirectory}/2022/2S/${semesterDirectory}`,
  },
  {
    year: 2021,
    semester1: `${documentDirectory}/2021/1S/${semesterDirectory}`,
    semester2: `${documentDirectory}/2021/2S/${semesterDirectory}`,
  },
  {
    year: 2020,
    semester1: "",
    semester2: `${documentDirectory}/2020/2S/${semesterDirectory}`,
  },
];
