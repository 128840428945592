import React from "react";
import "./BenefitsSection.css";

function Card({ title, body }) {
  return (
    <div className="col-lg-4">
      <div className="my-card p-3 m-3">
        <p className="card-title">{title}</p>
        <div className="accent-line" />
        <p className="card-text mt-3">{body}</p>
      </div>
    </div>
  );
}

export default function BenefitsSection() {
  const Cards = [
    { title: "PARCELAS QUE CABEM NO BOLSO", body: "Você pode financiar em muitas vezes para que o pagamento das contas vencidas caiba no seu bolso." },
    {
      title: "PARCELAMENTO A PARTIR DA PRIMEIRA CONTA",
      body: "Se você estiver com dificuldade de pagar, temos uma ótima opção de financiamento em até 15x.",
    },
    { title: "FÁCIL, SIMPLES, DESCOMPLICADO E SEGURO", body: "Você pode ficar tranquilo, cuidamos dos dados que você preenche para a análise do crédito." },
    { title: "NÃO PRECISA TER CONTA EM BANCO", body: "Por meio de nossa financeira você tem acesso direto à plataforma para financiar sua conta atrasada ou em aberta." },
    {
      title: <span>PRÁTICO,<br/> 100% ONLINE</span>,
      body: "Não precisa telefonar, é 100% online. Você acessa o site e faz uma simulação de financiamento a qualquer hora do dia e sem sair de casa.",
    },
    {
      title: "COBRADAS NA SUA CONTA DE ENERGIA",
      body: "Você faz o pagamento junto com o consumo de energia do mês, sem ter que se preocupar com um novo boleto.",
    },
  ];
  return (
    <div className="container  pb-5" id="benefits">
      <div className="row justify-content-center pb-5 mb-5">

        <div className="section">
          <div className="line" />
          <h3>Vantagens</h3>
          <div className="line" />
        </div>

        <h4>Financiamento <span className="highlight-accent">descomplicado</span>, seguro e com condições atrativas para pagar contas de energia atrasadas</h4>

        <div className="row">
          {Cards.map((item) => (
            <Card title={item.title} body={item.body} key={item.title} />
          ))}
        </div>
      </div>
    </div>
  );
}
