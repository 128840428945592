export const getUrl = () => {
    const { hostname } = window.location;
    let url;

    switch (hostname) {
      case "localhost":
        url = "https://dashboard-stg.cpfltotal.com.br";
        break;
      case "stg.alesta.com.br":
        url = "https://dashboard-stg.cpfltotal.com.br";
        break;
      case "alesta.com.br":
      case "www.alesta.com.br":
        url = "https://dashboard.cpfltotal.com.br";
        break;
      default:
        url = "https://dashboard-stg.cpfltotal.com.br";
    }
    return url;
  };