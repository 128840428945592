import axios from "axios";

import Url from "./endpoints";

export function getAppBaseUrl() {
  const { hostname } = window.location;
  let apiUrl;

  switch (hostname) {
    case "localhost":
      apiUrl = Url.local;
      break;
    case "stg.alesta.com.br":
      apiUrl = Url.stg;
      break;
    case "alesta.com.br":
    case "www.alesta.com.br":
      apiUrl = Url.prod;
      break;
    default:
      apiUrl = Url.stg;
  }

  return apiUrl;
}

export const getInstance = () => {
  const instance = axios.create({
    baseURL: getAppBaseUrl(),
    headers: {
      "Content-Type": "application/json",
    },
  });

  return instance;
};
