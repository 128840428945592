import { Modal } from 'antd'
import React from 'react'

import styles from "./CorrespondentCountry.module.css"

export const CorrespondentCountry = ({ModalVisibilityCorrespondentCountry, setModalVisibilityCorrespondentCountry}) => {
  return (
    <Modal
        title=""
        centered
        open={ModalVisibilityCorrespondentCountry}
        wrapClassName={styles.modalCorrespondenCountry}
        footer={null}
        onOk={() => setModalVisibilityCorrespondentCountry(false)}
        onCancel={() => setModalVisibilityCorrespondentCountry(false)}
      >
        <p>
          <strong>CPFL Total</strong>
        </p>
        <p><strong>CNPJ:</strong> 12.116.118/0001-69</p>
        <p><strong>Endereço:</strong> Rua Vigato, 1620, Térreo – Parte A – Núcleo Residencial Doutor João Aldo Nassif, Jaguariúna/SP, CEP 13.916-070.</p>
        <p><strong>Atividade:</strong> Recepção e encaminhamento de propostas de operações de crédito.</p>
      </Modal>
  )
}
