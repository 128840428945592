import React from "react";
import AboutSection from "../components/AboutSection";
import BenefitsSection from "../components/BenefitsSection";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import HowItWorkSection from "../components/HowItWorkSection";
import PrimarySection from "../components/PrimarySection";
import Banner from "../components/Banner";

export default function Home() {
  return (
    <>
      <Header />
      <main>
        <PrimarySection />
        <AboutSection />
        <BenefitsSection />
        <HowItWorkSection />
        <Banner />
        <Contact />
      </main>
      <Footer />
    </>
  );
}
