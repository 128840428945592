import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import FinancialStatements from "./FinancialStatements/FinancialStatements";
import { useState } from "react";
import { CorrespondentCountry } from "./CorrespondentCountry/CorrespondentCountry";

export default function Footer() {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [
    modalVisibilityFinancialStatements,
    setModalVisibilityFinancialStatements,
  ] = useState(false);

  const [
    ModalVisibilityCorrespondentCountry,
    setModalVisibilityCorrespondentCountry,
  ] = useState(false);

  return (
    <>
      <footer className="primary-bg footer">
        <div className="container pt-5 pb-5">
          <div className="row mt-5">
            <div className="col-auto">
              <p className="footer-text">
                Alesta Sociedade de Crédito Direto S.A. está inscrita no CNPJ
                sob o nº 38.316.293/0001-93 – Rua Vigato, 1.620, Núcleo
                Residencial Dr. João Aldo Nassif, Jaguariúna/SP – CEP:
                13.916-070.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <p className="footer-text">
                A Alesta não cobra nenhum valor antecipado para aprovar seu
                crédito. Caso você seja contatado por alguém se passando pela
                Alesta, e cobrando qualquer valor antecipado, encerre o contato
                imediatamente e entre em contato com a gente. A análise de
                crédito e cálculo das taxas de juros dependem de diversas
                informações disponibilizadas por você, tais como dados pessoais,
                valor solicitado e número de parcelas. Toda avaliação será
                realizada conforme as diretrizes de crédito da Alesta. Antes da
                contratação, você receberá todas as condições e informações
                relativas ao empréstimo, incluindo impostos incidentes (IOF) e o
                custo efetivo total (CET) da operação. O atraso ou não pagamento
                de prestação do contrato de empréstimo pessoal pode ter
                consequências legais, tais como a inclusão de nome nos cadastros
                dos órgãos de proteção ao crédito.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-auto">
              <p className="footer-text">
                Prazo de pagamento: O período mínimo é de 4 (quatro) meses e o
                máximo de 15 (quinze) meses. A taxa de juros varia entre 9,9% e
                18,9% ao mês (210,4% a 698% ao ano)*.
                <br />
                *Exemplo: um empréstimo de R$1.000,00 para pagar em 4 (quatro)
                meses, com juros de 9,9% a.m. (210,4% a.a.) e CET de 10,32% a.m.
                (230,23% a.a.) fechado na data de 02/05/2023, com vencimento da
                primeira parcela em 16/06/2023, terá parcelas de R$335,10 e um
                custo total de R$1.340,39.
              </p>
              <p className="footer-text tiny-text">
                *Estes valores são exemplificativos e poderão variar de acordo
                com os critérios de aprovação de crédito do cliente e da opção
                de parcelamento.
              </p>
            </div>
          </div>

          </div>

          <div className="footer-logo">
          <div className="footer-logo-content">
            <div>
              <img
                src="assets/images/logo-alesta.png"
                alt="logo-alesta"
                width={140}
              />
              
            </div>

            <p>
              Fazemos parte da Associação Nacional das Instituições de Crédito,
              Financiamento e Investimento (Acrefi).
            </p>

            <div>
              <img
                src="assets/images/logo-acrefi.jpg"
                alt="logo-acrefi"
                width={240}
              />
            </div>
          </div>
        </div>

        <div className="container pb-5">
          <div className="row text-white justify-content-center mt-5">
            <div className="col-lg-3 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  to="/privacy.pdf"
                  target="_blank"
                  className="link-style link-footer text-center"
                >
                  Aviso de Privacidade
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  to="/scr.pdf"
                  target="_blank"
                  className="link-style link-footer text-center"
                >
                  SCR
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <Link
                  to="/terms.pdf"
                  target="_blank"
                  className="link-style link-footer text-center"
                >
                  Termos de Uso
                </Link>
              </div>
            </div>
          </div>

          <div className="row text-white justify-content-center mt-0">
            <div className="col-lg-3 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="link-style link-footer text-center"
                  onClick={() => setModalVisibilityFinancialStatements(true)}
                >
                  Demonstrações Financeiras
                </span>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="link-style link-footer text-center"
                  onClick={() => setModalVisibility(true)}
                >
                  Ouvidoria
                </span>
              </div>
            </div>

            <div className="col-lg-3 col-sm-12">
              <div className="d-flex justify-content-center align-items-center">
                <span
                  className="link-style link-footer text-center"
                  onClick={() => setModalVisibilityCorrespondentCountry(true)}
                >
                  Correspondente no País
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="copy container pb-3">
          <span>Alesta SCD. Todos os direitos reservados.</span>
        </div>
      </footer>

      <Modal
        title="Ouvidoria Alesta"
        centered
        open={modalVisibility}
        wrapClassName="partners-modal"
        footer={null}
        onOk={() => setModalVisibility(false)}
        onCancel={() => setModalVisibility(false)}
      >
        <p className="footer-link ml-5 mr-5">
          Orientamos a você entrar em contato primeiro com o nosso SAC. <br />
          Se o problema persistir, estaremos à disposição para atendê-lo (a) na ouvidoria.
        </p>
        <p className="footer-link">
          E-mail:{" "}
          <a href="mailto:ouvidoria@alesta.com.br"> ouvidoria@alesta.com.br</a>
        </p>
        <p className="footer-link">
          Telefone: <a href="tel:08005554556">0800 555 4556</a>
        </p>
      </Modal>

      <CorrespondentCountry
        setModalVisibilityCorrespondentCountry={
          setModalVisibilityCorrespondentCountry
        }
        ModalVisibilityCorrespondentCountry={
          ModalVisibilityCorrespondentCountry
        }
      />

      <FinancialStatements
        modalVisibilityFinancialStatements={modalVisibilityFinancialStatements}
        setModalVisibilityFinancialStatements={
          setModalVisibilityFinancialStatements
        }
      />
    </>
  );
}
