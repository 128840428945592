import React from "react";
import { Button, TextField } from "@material-ui/core";
import { message } from "antd";
import { Controller, useForm } from "react-hook-form";
import { getInstance } from "../api/axios";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

export default function Contact() {
  const { handleSubmit, control, reset } = useForm();

  const messageStyle = {
    marginTop: "20vh",
  };

  const defaultValues = {
    clientEmail: "",
    clientName: "",
    clientPrimaryDocument: "",
    message: "",
    subjectMatter: "",
  };

  const onSubmit = async (body) => {
    let key = "send";
    const instance = getInstance();
    try {
      message.loading({ content: "Enviando...", key, style: messageStyle });
      await instance.post("/utils/contactAlesta", body);
      message.destroy();
      message.success({
        content: "Sua mensagem foi enviada com sucesso",
        style: messageStyle,
        key,
        duration: 3,
      });
      reset(defaultValues);
    } catch {
      reset(defaultValues);
      message.destroy();
      message.error({
        content:
          "Houve um erro ao enviar sua mensagem, tente novamente mais tarde",
        style: messageStyle,
      });
    }
  };

  return (
    <div className="container mt-5" id="contact">
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className="row justify-content-center">
            <div className="section">
              <div className="line" />
              <h3>Dúvidas?</h3>
              <div className="line" />
            </div>
          </div>
          <h4>
            Ainda ficou com alguma dúvida? Sem problemas, <br />a gente
            esclarece <span className="highlight-accent">para você.</span>
          </h4>

          <div className="row mt-5">
            <div className="col-lg-6 col-sm-12 mt-5">
              <h3 className="text-left m-0">Fale com a gente</h3>

              <p className="text-left mt-3">
                Envie suas dúvidas preenchendo o formulário ao lado.
              </p>
              <h3 className="text-left m-0 mt-5 mb-3">SAC</h3>
              <p className="text-left contacts">
                <PhoneOutlined />
                0800 555 4556
              </p>
              <p className="text-left contacts">
                <MailOutlined />
                <a
                  href="mailto:atendimento@alesta.com.br"
                  className="link-mail"
                >
                  atendimento@alesta.com.br
                </a>
              </p>
              <p className="text-left pb-5">
                Atendimento de segunda a sexta-feira das 8h às 17h.
              </p>
              <p className="text-left mt-5">
                Acesse as diretrizes da LGPD,{" "}
                <a
                  target="_blank"
                  href="https://privacyportal-br.onetrust.com/webform/853501c3-ce3d-4409-8cf6-537b6a1e703b/3404a942-7cd6-4aeb-883d-1d7490237add"
                  rel="noreferrer"
                >
                  clique aqui
                </a>
                .
              </p>
            </div>

            <div className="col-lg-6 col-sm-12 mt-5">
              <div className="soft-card p-5 d-flex flex-column justify-content-center align-items-center">
                <p className="soft-card-title m-0">Nos envie uma mensagem</p>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                  <Controller
                    name="clientName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className="soft-card-input mt-2"
                        required
                        id="clientName"
                        label="Seu nome completo"
                        fullWidth
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <br />
                  <Controller
                    name="clientEmail"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="clientEmail"
                        className="soft-card-input mt-2"
                        required
                        fullWidth
                        label="Email"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                    control={control}
                  />
                  <br />
                  <Controller
                    name="clientPrimaryDocument"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="clientPrimaryDocument"
                        type="tel"
                        className="soft-card-input mt-2"
                        required
                        fullWidth
                        label="CPF/CNPJ"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <br />
                  <Controller
                    name="subjectMatter"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        className="soft-card-input mt-2"
                        required
                        label="Assunto"
                        fullWidth
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  <br />
                  <Controller
                    control={control}
                    name="message"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <TextField
                          className="soft-card-input mt-5"
                          label="Digite a sua mensagem"
                          required
                          fullWidth
                          multiline
                          minRows={4}
                          variant="filled"
                          onChange={onChange}
                          value={value}
                        />
                      );
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    color="primary"
                    fullWidth
                    className="mt-5"
                  >
                    ENVIAR MENSAGEM
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
