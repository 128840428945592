import React from "react";
import "./Banner.css";
import Button from "@material-ui/core/Button";

export default function Banner() {
  return (
    <div className="primary-bg" id="about">
      <div className="container" style={{ position: "relative" }}>
        <img
          src="assets/svgs/banner.svg"
          alt="Banner"
          className="banner-image"
        />
        <div className="col-md-12 pt-5 pl-5 pr-5 pb-5">
          <div className="banner-wrapper">
            <div className="image-placeholder"></div>
            <h1 className="title banner-title">
              A Alesta te ajuda a construir uma{" "}
              <span className="highlight-accent">vida financeira saudável</span>
            </h1>
            <div className="call-to-action">
              <h3>
                Acesse nosso <br />
                Guia Financeiro
              </h3>
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  href="/guia-financeiro.pdf"
                  className="btn-simulate btn-document"
                  target="_blank"
                >
                  Clique Aqui
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
