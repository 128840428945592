import React from "react";
import "./PrimarySection.css";
import Button from "@material-ui/core/Button";
import { getUrl } from "../utils/getUrl";

export default function PrimarySection() {
  const url = getUrl(); 
  return (
    <div className="primary-bg" id="about">
      <div className="container">
        <img src="assets/images/banner.png" alt="" className="rounded banner" />
        <div className="row">
          <div className="col-md-12 pt-5 pl-5 pb-5">
            <div className="h-100 d-flex flex-column justify-content-center align-items-start">
              <h1 className="title">
                Não conseguiu pagar <br />
                sua <span className="highlight-accent">conta de energia?</span>
              </h1>
              <h2 className="subtitle">
                Fique tranquilo, a gente <br />
                paga para você!
              </h2>
              <Button
                variant="contained"
                size="large"
                color="primary"
                href={`${url}/register`}
                className="btn-simulate mt-5"
                target="_blank"
              >
                Simule Agora
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
