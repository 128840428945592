import React from "react";
import { Collapse } from "antd";

const { Panel } = Collapse;

export default function FAQ() {
  const questions = [
    {
      title:
        "Já tenho um contrato ativo com a Alesta, consigo realizar um novo financiamento?",
      body: "Não. Se você tem um contrato em vigência é necessário primeiro liquidá-lo para depois solicitar um novo financiamento.",
    },
    {
      title: "Qual é a forma de pagamento do financiamento?",
      body: "O financiamento é pago direto na conta de energia e ocorre nos meses subsequentes após efetivação do contrato. Caso não seja possível inserir a parcela em conta de energia, a cobrança será em outro formato, por exemplo, via boleto.",
    },
    {
      title:
        "Uma parcela do financiamento não entrou em minha conta de energia, o que pode ter acontecido?",
      body: "Se a parcela em questão for a primeira e, ao contratar o financiamento sua conta de energia já estiver fechada, o pagamento ficará para o próximo mês. A financeira tem até 45 dias para lançar a cobrança na fatura, após o fechamento do contrato. Se algum problema ocorrer durante esse processo, a financeira a qual realizou o financiamento entrará em contato para acertar a cobrança.",
    },
    {
      title:
        "A parcela do financiamento não entrou em minha conta de energia, posso pedir que seja inserida na próxima fatura?",
      body: "Não é necessário pedir. A parcela que não caiu em sua conta será automaticamente cobrada por boleto via e-mail.",
    },
    {
      title: "Consigo transferir o meu financiamento para o novo morador?",
      body: (
        <span>
          Não é possível, pois o financiamento está vinculado aos dados pessoais (como nome e CPF/CNPJ) do titular da conta de energia que fechou o contrato.
          <br />
          Orientamos a entrar em contato com a financeira responsável pelo financiamento para verificar a melhor tratativa.
        </span>
      ),
    },
    {
      title: "De que forma a Alesta entra em contato com o cliente?",
      body: (
        <span>
          Nosso atendimento é via e-mail, telefone ou WhatsApp, em horário comercial, entre 8h e 17h. 
          <br />
          IMPORTANTE: a Alesta SCD nunca solicitará suas senhas e não cobrará por análise de crédito.
        </span>
      ),
    },
    {
      title: "Em até quantas parcelas posso financiar minha conta de energia?",
      body: "Se aprovado pela análise de crédito você pode financiar sua conta de energia em até 15 parcelas de forma rápida e com parcelas acessíveis.",
    },
    {
      title: "Quais as condições para pedir o financiamento à Alesta?",
      body: (
        <span>
          O financiamento é de forma descomplicada e sem burocracia, com as
          seguintes condições:
          <br />
          <br />
          - Você precisa ser aprovado na análise de crédito;
          <br />
          - Não pode ter contrato de financiamento ativo com a distribuidora da
          CPFL Energia;
          <br />
          - O financiamento, obrigatoriamente, deverá ser em nome do titular da
          conta de energia;
          <br />
          - As parcelas devem ter o valor abaixo de R$ 400,00;
          <br />- E o financiamento é permitido, no máximo, para até 3 (três)
          faturas de energia elétrica.
        </span>
      ),
    },
    {
      title:
        "Quanto tempo demora para ter a resposta sobre minha solicitação de financiamento?",
      body: "Você faz a simulação e em alguns segundos é exibida as propostas para escolher a que melhor se encaixa no seu orçamento e assim prosseguir com a contratação.",
    },
    {
      title: "Posso solicitar o cancelamento do financiamento contratado?",
      body: (
        <span>
          Sim. O consumidor pode desistir do contrato no prazo de 7 dias
          contados de sua assinatura, conforme estabelece o art. 49 do Código de
          Defesa do Consumidor.
          <br />
          <br />
          Para mais informações sobre os procedimentos e regras de cancelamento,
          entre em contato conosco através do telefone 0800 555 4556 ou pelo
          e-mail: atendimento@alesta.com.br.
        </span>
      ),
    },
    {
      title: "Posso antecipar o pagamento de minhas parcelas?",
      body: (
        <span>
          Sim, você pode. Para prosseguir envie um e-mail para{" "}
          <a href="mailto:cobranca@alesta.com.br">cobranca@alesta.com.br</a> ou{" "}
          <a href="https://wa.me/5519996903716">clique aqui</a> para acessar o
          nosso chat.
        </span>
      ),
    },
    {
      title: "Posso quitar a minha dívida com a Alesta?",
      body: (
        <span>
          Sim. Você pode quitar o seu contrato a qualquer momento. Para isso,
          basta enviar uma mensagem em nosso{" "}
          <a href="https://wa.me/5519996903716">chat</a> ou um{" "}
          <a href="mailto:cobranca@alesta.com.br">e-mail</a> para continuar com
          a solicitação.
        </span>
      ),
    },
    {
      title: "Em quantos dias o pagamento da parcela será baixado?",
      body: "O nosso sistema pode levar até 3 dias úteis para identificar o pagamento e dar baixa na parcela.",
    },
    {
      title: "Quanto tempo leva para o contrato ser baixado após pagamento de quitação?",
      body: "O nosso sistema pode levar até 5 dias úteis para identificar o pagamento e dar baixa nas parcelas faltantes para quitação do contrato.",
    },
  ];
  return (
    <div className="container" id="faq">
      <div className="row mt-5 pt-5 pb-3 mb-5 justify-content-center">
        <div className="col-auto">
          <div className="row justify-content-center">
            <div className="section">
              <div className="line" />
              <h3>FAQ - Perguntas Frequentes</h3>
              <div className="line" />
            </div>
          </div>
        </div>
      </div>

      <Collapse className="my-collapse">
        {questions.map((item) => {
          return (
            <Panel className="my-panel" header={item.title} key={item.title}>
              <p className="my-panel-body">{item.body}</p>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
