import React, { useEffect } from "react";
import FAQ from "../components/FAQ";
import Footer from "../components/Footer";

import { animateScroll as scroll } from "react-scroll";
import HeaderFaq from "../components/HeaderFaq";

export default function FaqPage() {
  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  return (
    <>
      <HeaderFaq />
      <main>
        <FAQ />
      </main>
      <Footer />
    </>
  );
}
