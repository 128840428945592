import { Table } from "antd";
import React from "react";

export default function PrivacyPolicyModalContent() {
  const columns = [
    {
      title: 'O que você pode pedir',
      dataIndex: 'whatYouCanAsk',
    },
    {
      title: 'Para que serve',
      dataIndex: 'howItworks',
    },
  ];
  const data = [
    {
      whatYouCanAsk: 'Revisão de decisões automatizadas',
      howItworks: 'Você poderá pedir a revisão de decisões que foram tomadas com base em tratamento de Dados Pessoais de forma automatizada.',
    },
    {
      whatYouCanAsk: 'Confirmação de tratamento de dados',
      howItworks: 'Confirmar se temos ou não Dados Pessoais sobre você em nosso banco de dados. ',
    },
    {
      whatYouCanAsk: 'Declaração completa de dados pessoais',
      howItworks: 'Obter todas as informações que temos sobre você na organização. ',
    },
    {
      whatYouCanAsk: 'Correção de dados incompletos, inexatos ou desatualizados',
      howItworks: 'Retificar seus Dados Pessoais. Nessa hipótese, a depender do tipo de dado que estiver sendo corrigido, a ALESTA poderá lhe pedir documentação comprobatória. ',
    },
    {
      whatYouCanAsk: 'Anonimização, bloqueio ou eliminação de dados pessoais',
      howItworks: 'Interromper o tratamento de Dados Pessoais que eventualmente estejam sendo realizados de forma excessiva, desnecessária ou em desconformidade com a LGPD. Eliminação/anonimização são direitos que somente serão executados quando não houver mais finalidade de tratamento em lei ou regulamentação específica que justifique a manutenção dos mesmos na ALESTA.',
    },
    {
      whatYouCanAsk: 'Oposição ao tratamento de dados',
      howItworks: 'Para que você discorde e justifique porque não está de acordo com o tratamento de seus Dados Pessoais.',
    },
    {
      whatYouCanAsk: 'Revogação e suspensão do consentimento',
      howItworks: 'Quando o tratamento de seus Dados Pessoais se der com base no consentimento, você poderá retirar (revogar) ou suspender a sua autorização a qualquer tempo.',
    },
    {
      whatYouCanAsk: 'Eliminação do tratamento de dados coletados sem o consentimento',
      howItworks: 'Quando a base para o tratamento dos seus Dados Pessoais for o consentimento, você poderá pedir que seus dados sejam excluídos da base de dados da ALESTA.',
    },
    {
      whatYouCanAsk: 'Portabilidade de Dados',
      howItworks: 'Encaminhamento dos Dados Pessoais, a pedido do titular, a outro fornecedor, sujeito à regulamentação da ANPD.',
    },
  ];

  return (
    <div>
      <p className="modal_body">A Alesta Sociedade de Crédito Direto, inscrita no CNPJ/ME sob o nº 38.316.293/0001-93 (“ALESTA”) entende como primordial garantir a segurança e a privacidade de quaisquer informações que identifiquem ou possam identificar uma pessoa natural (“Dados Pessoais”).</p>
      <p className="modal_body">Por esse motivo, descrevemos neste Aviso de Privacidade as principais informações acerca do tratamento de Dados Pessoais realizado pela ALESTA, para a execução de nossas atividades e serviços, bem como descrevemos os seus direitos com relação a esses Dados Pessoais.</p>
      <p className="modal_body">Com isso, reafirmamos o nosso compromisso com a privacidade dos seus Dados Pessoais e transparência em relação a tratamentos realizados</p>
      <p className="modal_body">POR FAVOR, RECOMENDAMOS QUE O AVISO DE PRIVACIDADE ABAIXO SEJA LIDO COM ATENÇÃO E <strong>CASO VOCÊ NÃO CONCORDE COM QUAISQUER DISPOSIÇÕES DESTE DOCUMENTO, ORIENTAMOS QUE VOCÊ NÃO UTILIZE OS NOSSOS SERVIÇOS.</strong></p>
      <p className="modal_body">VOCÊ EXPRESSAMENTE AUTORIZA A ALESTA A REALIZAR CONSULTAS EM INSTITUIÇÕES PÚBLICAS, NO SISTEMA DE INFORMAÇÕES DE CRÉDITO (SCR) SOB GESTÃO DO BANCO CENTRAL, BUREAUS DE CRÉDITO E BUREAUS DE DADOS, EMPRESAS DE ANTIFRAUDE E DEMAIS INSTITUIÇÕES COMPLEMENTARES QUE SE FIZEREM NECESSÁRIAS.</p>
      <p className="modal_body">O TRATAMENTO DE DADOS PESSOAIS INFORMADO NESTE DOCUMENTO É CONDIÇÃO PARA ACESSO E USO DAS FUNCIONALIDADES DA ALESTA, PORTANTO RECOMENDAMOS A LEITURA COMPLETA E ATENTA DO PRESENTE AVISO DE PRIVACIDADE A FIM DE QUE TENHA CONHECIMENTO PLENO DOS TRATAMENTOS QUE A ALESTA REALIZARÁ COM OS SEUS DADOS PESSOAIS AO ENVIAR UMA PROPOSTA DE CONTRATAÇÃO, ACESSAR AS FUNCIONALIDADES DE SITES E PLATAFORMAS DA INSTITUIÇÃO E/OU AO INFORMAR SEUS DADOS PESSOAIS. </p>
      <p className="modal_body">Este Aviso de Privacidade dispõe sobre:</p>

      <p className="modal_body"><b>1. O que é o Aviso de Privacidade?</b></p>
      <p className="modal_body"><b>2. A quem se destina o Aviso de Privacidade?</b></p>
      <p className="modal_body"><b>3. Quais Dados Pessoais a ALESTA trata e para quais finalidades?</b></p>
      <p className="modal_body"><b>4. Quais requisitos a ALESTA observa para realizar o tratamento de Dados Pessoais?</b></p>
      <p className="modal_body"><b>5. Para quais finalidades a ALESTA trata Dados Pessoais?</b></p>
      <p className="modal_body"><b>6. A ALESTA trata Dados Pessoais de menores de 18 (dezoito) anos?</b></p>
      <p className="modal_body"><b>7. Onde seus Dados Pessoais são armazenados?</b></p>
      <p className="modal_body"><b>8. Por quanto tempo tratamos seus Dados Pessoais e quando eles serão excluídos?</b></p>
      <p className="modal_body"><b>9. Quando compartilhamos seus Dados Pessoais com terceiros?</b></p>
      <p className="modal_body"><b>10. Quais medidas de segurança são adotadas no tratamento dos Dados Pessoais?</b></p>
      <p className="modal_body"><b>11. O que é um incidente de segurança envolvendo Dados Pessoais (incidente de dados)?</b></p>
      <p className="modal_body"><b>12. O que é um Cookie? A ALESTA coleta cookies em seus sites ou aplicativos?</b></p>
      <p className="modal_body"><b>13. Quais são meus direitos como Titular de dados e como faço para exercê-los?</b></p>
      <p className="modal_body"><b>14. Decisões automatizadas</b></p>
      <p className="modal_body"><b>15. Quem é o encarregado de dados da ALESTA?</b></p>
      <p className="modal_body"><b>16. Este Aviso de Privacidade pode ser atualizado?</b></p>
      <p className="modal_body"><b>17. Glossário</b></p>

      <p className="modal_body_title">1.	O que é o Aviso de Privacidade?</p>
      <p className="modal_body">1.1.	Este Aviso de Privacidade tem como objetivo descrever como, na qualidade de Controlador de Dados Pessoais utilizamos, coletamos, compartilhamos ou de qualquer outra forma realizamos o tratamento dos Dados Pessoais de indivíduos (“Titulares de Dados”), no desempenho de nossas atividades e, ainda, apresentar os seus direitos com relação aos seus Dados Pessoais, conforme previsto na Lei Geral de Proteção de Dados (Lei nº 13.709/18 ou “LGPD”), e demais legislações e regulamentações aplicáveis.</p>
      <p className="modal_body">1.2.	Em caso de sugestões, dúvidas ou caso tenha interesse em exercer os seus direitos relacionados aos seus Dados Pessoais, pedimos para que entre em contato conosco por meio do nosso canal de privacidade, que pode ser acessado através deste <a href="https://privacyportal-br.onetrust.com/webform/853501c3-ce3d-4409-8cf6-537b6a1e703b/00c62b12-6a56-463d-82aa-0d69f84f37eb">link</a>.</p>

      <p className="modal_body_title">2.	A quem se destina o Aviso de Privacidade?</p>
      <p className="modal_body">2.1.	Este Aviso de Privacidade se destina à qualquer pessoa física Titular de Dados Pessoais que:</p>
      <p className="modal_body_item">(i)	seja ou tenha sido nosso cliente;</p>
      <p className="modal_body_item">(ii)	tenha ou teve algum relacionamento conosco ou com quem pretendemos nos relacionar;</p>
      <p className="modal_body_item">(iii)	seja ou tenha sido representante, procurador, empregado ou sócio de algum cliente, empresa ou entidade com a qual nos relacionamos ou tivemos alguma relação no passado;</p>
      <p className="modal_body_item">(iv)	realize ou tenha realizado alguma transação conosco;</p>
      <p className="modal_body_item">(v)	e/ou visite ou tenha visitado o nosso website.</p>
      <p className="modal_body_title">2.2.	Para nos referirmos aos Titulares de Dados indicados acima neste Aviso de Privacidade, utilizaremos os termos “você” ou “usuário”.</p>
      <p className="modal_body">INFORMAMOS QUE AO ACESSAR OU ENCAMINHAR UMA PROPOSTA DE CONTRATAÇÃO, ACESSAR AS FUNCIONALIDADES DESTE SITE OU DA APLICAÇÃO DA ALESTA E/OU INFORMAR SEUS DADOS PESSOAIS, ENTENDEREMOS QUE VOCÊ TEM CIÊNCIA E ENTENDEU AS DISPOSIÇÕES DO PRESENTE AVISO DE PRIVACIDADE, INCLUINDO AS RAZÕES PELAS QUAIS A ALESTA REALIZA O TRATAMENTO DE SEUS DADOS PESSOAIS.</p>

      <p className="modal_body_title">3.	Quais Dados Pessoais a ALESTA trata e para quais finalidades?</p>
      <p className="modal_body">3.1.	A ALESTA tratará Dados Pessoais informados diretamente por você e/ou pela empresa que você representa ou é sócio/administrador, obtidos de fontes legítimas de empresas parceiras, como empresas do mesmo grupo econômico, como a CPFL Total Serviços Administrativos S.A. (“CPFL TOTAL") e/ou prestadores de serviços (tais como bureaus de dados, bureaus de crédito e agências de crédito), coletados automaticamente, quando da utilização dos nossos Produtos e Serviços e/ou disponíveis em bases públicas e publicamente acessíveises.</p>
      <p className="modal_body">3.2.	Poderemos receber os seus Dados Pessoais do parceiro CPFL TOTAL, mediante submissão voluntária do usuário pessoa física ou sócio, administrador ou representante legal de usuário pessoa jurídica, mediante o preenchimento da ficha cadastral e submissão voluntária de simulação de crédito.</p>

      <p className="modal_body">3.3.	Dessa forma, na execução de nossas atividades podemos realizar o tratamento dos seguintes Dados Pessoais:</p>
      <p className="modal_body_item">(i)	Informações de identificação: nome, endereço, e-mail, gênero, nome da mãe, telefone, data de nascimento, nacionalidade, estado civil, tipo de residência, RG, CPF e ocupação.</p>
      <p className="modal_body_item">(ii)	Informações sobre navegação e dispositivos: endereço de IP, data e hora de acessos, ID da sessão, versão do dispositivo utilizado, nomes de domínio, áreas do site que você visita, seu sistema operacional, a versão do sistema operacional que você usa, a versão do navegador e o URL anterior à sua visita, localização geográfica, fonte de referência, tipo de navegador, clickstream, duração da visita e páginas visitadas. </p>
      <p className="modal_body_item">(iii)	Informações financeiras: renda mensal, renda familiar, renda extra, score de crédito, histórico de pagamento de conta de energia, cópia de documentos das operações de crédito firmadas com a ALESTA.</p>
      
      <p className="modal_body">3.4.	Os Dados Pessoais indicados acima podem ser tratados para as seguintes finalidades:</p>
      <p className="modal_body_item">(i)	Realização e atualização do cadastro do usuário;</p>
      <p className="modal_body_item">(ii)	Validação e autenticação da identidade do usuário;</p>
      <p className="modal_body_item">(iii)	Simulação de proposta;</p>
      <p className="modal_body_item">(iv)	Formalização de propostas e contratos;</p>
      <p className="modal_body_item">(v)	Prestação dos Serviços;</p>
      <p className="modal_body_item">(vi)	Avaliação, gestão e oferta de crédito e financiamento;</p>
      <p className="modal_body_item">(vii)	Formação de perfil de consumo e de crédito;</p>
      <p className="modal_body_item">(viii)	Parcelamento da conta de energia;</p>
      <p className="modal_body_item">(viii)	Parcelamento da conta de energia;</p>
      <p className="modal_body_item">(ix)	Cobrança e negociação de créditos; </p>
      <p className="modal_body_item">(x)	Suporte ao usuário;</p>
      <p className="modal_body_item">(xi)	Registro de reclamações e solicitações do usuário;</p>
      <p className="modal_body_item">(xii)	Comunicação com o usuário;</p>
      <p className="modal_body_item">(xiii)	Análise e prevenção a atividades ilícitas, como fraude, lavagem de dinheiro, financiamento ao terrorismo, entre outras;</p>
      <p className="modal_body_item">(xiv)	Manutenção da segurança e integridade dos Serviços;</p>
      <p className="modal_body_item">(xv)	Realização de publicidade e marketing, incluindo a oferta de produtos e serviços;</p>
      <p className="modal_body_item">(xvi)	Efetivação de pesquisas de mercado para publicidade e marketing;</p>
      <p className="modal_body_item">(xvii)	Aprimoramento da experiência do usuário;</p>
      <p className="modal_body_item">(xviii)	Desenvolvimento e aprimoramento de produtos e serviços;</p>
      <p className="modal_body_item">(xix)	Correção de falhas e erros identificados em nossa plataforma; </p>
      <p className="modal_body_item">(xx)	Realização de auditorias internas;</p>
      <p className="modal_body_item">(xxi)	Exercício regular de direitos da ALESTA; </p>
      <p className="modal_body_item">(xxii)	Cumprimento de obrigações legais ou regulatórias; e</p>
      <p className="modal_body_item">(xxiii)	Cumprimento de decisões e/ou ordens de autoridades, na medida do permitido por lei.</p>

      <p className="modal_body">3.5.	Para realizar a análise de crédito ou para conseguir obter as melhores condições nas operações de crédito, a ALESTA poderá consultar bancos de dados e cadastros relativos a consumidores, os serviços de proteção ao crédito e congêneres, tais como Serviço de Proteção ao Crédito (SPC) e SERASA – Centralização de Serviços os Bancos S.A., Cadastro de Emitentes de Cheques Sem Fundos (CCF) mantido pelo Banco Central do Brasil, Cadastro Positivo (score) e quaisquer outros órgãos, entidades ou empresas, julgados pertinentes pela ALESTA para fins da análise de crédito e cobrança, durante todo o tempo em que o usuário tiver com seu contrato em andamento, valores em aberto e ou obrigações não liquidadas, decorrentes de operações de crédito firmadas com a ALESTA.</p>
      <p className="modal_body">3.6.	PARA CONSEGUIR OBTER AS MELHORES CONDIÇÕES NAS OPERAÇÕES DE CRÉDITO, O USUÁRIO EXPRESSAMENTE AUTORIZA A ALESTA A CONSULTAR AS INFORMAÇÕES CONSOLIDADAS EM SEU NOME NO SISTEMA DE INFORMAÇÕES DE CRÉDITO DO BANCO CENTRAL (SCR) E/OU OUTRO SISTEMA QUE EM VIRTUDE DE NORMA LEGAL O SUBSTITUA. O SCR É UM BANCO DE DADOS SOBRE INFORMAÇÕES REMETIDAS AO BANCO CENTRAL DO BRASIL, REFERENTE ÀS OPERAÇÕES E TÍTULOS COM CARACTERÍSTICAS DE CRÉDITO E RESPECTIVAS GARANTIAS CONTRATADOS POR CLIENTE PERANTE INSTITUIÇÕES FINANCEIRAS.</p>

      <p className="modal_body_title">4.	Quais requisitos a ALESTA observa para realizar o tratamento de Dados Pessoais?</p>
      <p className="modal_body">4.1.	Seguimos o disposto em leis e regulamentações que regem a privacidade e a proteção de dados no Brasil, em especial a LGPD para determinar se e como trataremos os seus Dados Pessoais. Abaixo relacionamos os critérios analisados por nós para o tratamento de Dados Pessoais:</p>      
      <p className="modal_body_item">(i)	Existência de uma previsão legal que permita tal atividade (conheça as hipóteses que a LGPD prevê para tratamento de dados no Glossário “Permissão legal para tratamento de dados pessoais”);</p>
      <p className="modal_body_item">(ii)	Ao definir o propósito do tratamento de Dados Pessoais, garantimos que esta seja legítima e específica, e que seja dada a devida transparência e informação ao Titular de Dados Pessoais sobre o que é feito com seus Dados Pessoais (o que é feito no Aviso de Privacidade ou em documentos legais complementares); </p>
      <p className="modal_body_item">(iii)	Utilizamos dados mínimos, necessários e compatíveis com o contexto do tratamento;</p>
      <p className="modal_body_item">(iv)	Adotamos medidas de segurança adequadas e que estão ao nosso alcance para proteção dos Dados Pessoais contra acesso indevido por terceiros não autorizados, de situações acidentais ou ilícitas, e sempre compatibilizando a sensibilidade, o volume, forma e métodos de tratamento;</p>
      <p className="modal_body_item">(v)	Se necessário, pedimos autorização (consentimento) ao Titular dos Dados para cumprir com a finalidade a ele informada previamente;</p>
      <p className="modal_body_item">(vi)	Ao tratarmos dados no legítimo interesse da empresa ou de terceiros nosso foco sempre será no menor impacto à privacidade do Titular dos Dados, bucando um equilíbrio entre os interesses da ALESTA ou de terceiros, a expectativa do Titular dos Dados com relação ao tratamento dos seus Dados Pessoais e avaliando se haverá algum impacto negativo aos seus direitos e garantias fundamentais;</p>
      <p className="modal_body_item">(vii)	Disponibilizamos um canal para que o Titular dos Dados exerça seus direitos relacionados aos Dados Pessoais que tratamos a seu respeito e tire dúvidas relacionadas à LGPD (vide pergunta “13 Quais são meus direitos como titular de dados e como faço para exercê-los?”).</p>
      
      <p className="modal_body_title">5.	A ALESTA trata Dados Pessoais de menores de 18 (dezoito) anos?</p>
      <p className="modal_body">5.1.	Os Serviços são disponíveis apenas a maiores de 18 (dezoito) anos ou emancipados. Dessa forma, não coletaremos ou conscientemente solicitamos Dados Pessoais de menores de 18 anos que não sejam emancipados. Usuários menores de 18 anos não devem tentar se registrar para ter acesso aos Serviços ou nos enviar qualquer Dado Pessoal. Se tomarmos conhecimento de que coletamos Dados Pessoais de um indivíduo menor de 18 anos em nossos sites e aplicativos, cancelaremos a conta criada. </p>

      <p className="modal_body_title">6.	Onde seus Dados Pessoais são armazenados?</p>
      <p className="modal_body">6.1.	Os Dados Pessoais tratados pela ALESTA são geralmente armazenados no Brasil, todavia para assegurar maior eficiência e melhor desempenho de nossas atividades, por vezes, os Dados Pessoais poderão ser transferidos para países estrangeiros. Nessa hipótese, a ALESTA continuará responsável pelos Dados Pessoais e assegurará aos Titulares de Dados Pessoais, que a transferência de Dados Pessoais para outro território será realizada de acordo com as leis e regulamentações que regem a proteção de dados e também nas políticas e diretrizes de segurança da informação da ALESTA.</p>

      <p className="modal_body_title">7.	Por quanto tempo tratamos seus Dados Pessoais e quando eles serão excluídos? </p>
      <p className="modal_body">7.1.	Nós mantemos os seus Dados Pessoais armazenados:</p>
      <p className="modal_body_item">(i)	pelo tempo necessário para atingir a finalidade para os quais os Dados Pessoais foram coletados, como por exemplo, enquanto o contrato de empréstimo/financiamento estiver vigente;</p>
      <p className="modal_body_item">(ii)	para defesa de direitos em ações ou processos administrativos, judiciais ou arbitrais;</p>
      <p className="modal_body_item">(iii)	para o exercício regular de direitos da ALESTA;</p>
      <p className="modal_body_item">(iv)	quando houver lei ou regulamentação específica que nos obrigue a manter o Dado Pessoal por determinado período, como, por exemplo, para fiscalização do Banco Central do Brasil, que determina que a ALESTA precisa realizar a guarda dos dados tratados (tanto para contratos de financiamento/empréstimos aprovados quanto para àqueles que não foram por ela aprovados);</p>
      <p className="modal_body_item">(v)	sempre que houver base legal que justifique a manutenção dos Dados Pessoais.</p>

      <p className="modal_body">7.2.	Seus dados pessoais serão excluídos de forma segura ou tornados não identificáveis (anonimizados) quando a finalidade para a qual foram coletados for atingida  ou quando não houver base legal que justifique a manutenção dos mesmos na ALESTA. </p>

      <p className="modal_body_title">8.	Quando compartilhamos seus Dados Pessoais com terceiros?</p>
      <p className="modal_body">8.1.	Os Dados Pessoais coletados poderão ser compartilhados:</p>
      <p className="modal_body_item">(i)	Com empresas do mesmo grupo, incluindo a CPFL Total e a Distribuidora, quando necessário para fins de atualização cadastral, a adequada prestação dos serviços, análise de crédito e prevenção à fraude, manutenção da integridade e segurança das plataformas, cumprimento de obrigação legal, cumprimento de decisões e/ou ordens de autoridades competentes na medida do exigido em lei e prevenção de fraudes e atividades ilícitas;</p>
      <p className="modal_body_item">(ii)	Com autoridades judiciais, administrativas ou governamentais competentes, na medida do exigido por lei;</p>
      <p className="modal_body_item">(iii)	Em caso de movimentações societárias, como fusão, aquisição ou incorporação, ou ainda na hipótese de cessão de direitos;</p>
      <p className="modal_body_item">(iv)	Com os provedores dos serviços contratados pela ALESTA para viabilizar o site e plataforma ALESTA juntamente com todas as suas funcionalidades e serviços disponibilizados;</p>
      <p className="modal_body_item">(v)	Com bancos de informações públicos ou privados para a validação de dados e para a autenticação dos usuários nos processos de contratação junto à ALESTA, para análise de crédito, atualização cadastral e prevenção à fraude;</p>
      <p className="modal_body_item">(vi)	De forma automática, com prestadores de serviços ou parceiros para apoio na realização das atividades da ALESTA, como por exemplo central de atendimento, comercialização de produtos e serviços, operação de infraestrutura técnica, cobrança e escritórios de advocacia;</p>
      <p className="modal_body_item">(vii)	Com terceiros para execução dos contratos formalizados com o usuário, tais como processamento de pagamento, dentre outros.</p>

      <p className="modal_body_title">9.	Quais medidas de segurança são adotadas no tratamento dos Dados Pessoais?</p>
      <p className="modal_body">9.1.	A ALESTA adota medidas de segurança, técnicas e administrativas pautadas nas melhores práticas do mercado, voltadas para a proteção dos Dados Pessoais contra acessos não autorizados e situações de destruição, perda, alteração, comunicação ou difusão indevidos ou não autorizados.</p>
      <p className="modal_body">9.2.	A ALESTA adota diversos mecanismos de segurança para evitar acesso indevido aos Dados Pessoais do usuário, entre eles a senha de acesso à conta do usuário na plataforma. O acesso à plataforma é realizado mediante o uso do login e senha, criada diretamente pelo usuário. </p>
      <p className="modal_body">9.3.	Ainda, outros mecanismos de segurança são utilizados pela ALESTA, como o controle de acesso a Dados Pessoais tratados dentro de ambientes controlados pela ALESTA, registro de atividades realizadas com Dados Pessoais dos usuários, aplicação e estruturação de políticas, normas e procedimentos internos para o tratamento de Dados Pessoais de forma segura e em conformidade com a legislação, dentre outros.</p>
      <p className="modal_body">9.4.	Os Dados Pessoais tratados pela ALESTA são armazenados em ambiente seguro e são observadas as regras e procedimentos internos de segurança da informação para tal armazenamento. As medidas de segurança adotadas são testadas periodicamente para garantir a privacidade e proteção dos Dados Pessoais dos Titulares.As pessoas que, para o exercício de suas atividades na ALESTA, têm autorização para realizar o tratamento de Dados Pessoais possuem compromisso de confidencialidade e preservação da privacidade das informações pessoais que acessam.</p>

      <p className="modal_body_title">10.	O que é um incidente de segurança envolvendo Dados Pessoais (incidente de dados)?</p>
      <p className="modal_body">10.1.	Um incidente de Dados Pessoais ocorre quando há a divulgação indevida ou o acesso não autorizado a Dados Pessoais.</p>
      <p className="modal_body">10.2.	Importante: nenhum sistema de segurança é infalível, e na eventualidade de a ALESTA ser vítima de uma fraude, crime ou acesso indevido que cause a exposição de Dados Pessoais, a ALESTA tomará todas as medidas necessárias para eliminar ou reduzir os impactos, o que inclui a aplicação de plano de resposta a incidentes, procedimentos e técnicas de análise, inclusive o uso legal e permitido de dados forenses, para auxiliar a identificar, monitorar, relatar e resolver qualquer incidente de violação de dados.</p>
      <p className="modal_body">10.3.	Mas não se preocupe, pois na hipótese acima, se mesmo com as ações tomadas o incidente de dados puder trazer risco ou dano relevante a sua privacidade, tanto você quanto a Autoridade Nacional de Proteção de Dados serão informados pela ALESTA sobre o evento, seus impactos e o que foi e está sendo feito para reduzir os riscos, tudo conforme disposto na LGPD e em regulamentações complementares. Para realizar esta comunicação ao Titular dos Dados, a ALESTA utilizará os canais de atendimento disponíveis à época para contato com o Titular dos Dados, podendo este se dar por telefone, e-mail ou carta, a critério da ALESTA.</p>
      <p className="modal_body">10.4.	Em caso de clientes ativos (com contratos em andamento, o que inclui eventual pendência no pagamento de parcelas do contrato de empréstimo/financiamento), serão utilizados preferencialmente o endereço de e-mail cadastrado em seu Banco de Dados ou através de comunicação no aplicativo ou site, portanto, não esqueça de manter seus dados sempre atualizados.</p>

      <p className="modal_body_title">11.	O que é um Cookie? A ALESTA coleta cookies em seu sites ou aplicativos?</p>
      <p className="modal_body">11.1.	A ALESTA utiliza cookies para análise do desempenho e audiência de seus websites e manutenção e aperfeiçoamento dos recursos e funcionalidades.</p>
      <p className="modal_body">11.2.	Um cookie é um arquivo que contém um identificador (uma sequência de letras e números) que é armazenado pelo seu navegador quando acessa sites utilizando a internet. </p>
      <p className="modal_body">11.3.	Em linhas gerais, os cookies coletados na internet, em área não logada, não te identificam diretamente, isto porque o navegador que você utiliza possui uma lista de identificadores dos usuários da internet e quanto você acessa um site, o identificador do seu equipamento (smartphone, notebook, computador, tablet, etc.) é acionado e o seu navegador bate na lista de identificadores e automaticamente informa ao site da ALESTA que aquele código está navegando no site ou em aplicação determinada.</p>
      <p className="modal_body">11.4.	A ALESTA coleta cookies necessários para o funcionamento do site (ou seja, para possibilitar o uso das suas funcionalidades), com objetivos funcionais de analytics, para facilitar a navegação, para melhor compreensão e otimização do desempenho do site/sessão/funcionalidades, para avaliar as páginas mais visualizadas e taxa de rejeição e para entregar conteúdos mais adequado a quem navega através da internet em nosso site.Este arquivo ficará armazenado em seu computador até que perca sua validade. Na Central de Preferências, no site da ALESTA, você poderá consultar todos os cookies que coletamos, sua categoria e duração, além de poder realizar a gestão de consentimento destes cookies. Você poderá, ainda, configurar seu navegador para que bloqueie a utilização dos cookies  durante a sua navegação, porém algumas funcionalidades do site podem não funcionar corretamente.</p>

      <p className="modal_body_title">12.	Quais são meus direitos como Titular de Dados e como faço para exercê-los?</p>
      <p className="modal_body">12.1.	A LGPD trouxe uma relação de direitos aos Titulares de Dados, como o direito de conhecer os seus Dados Pessoais tratados pelo controlador, o de ter acesso a qualquer tempo à tais informações, de saber quem é o controlador desses Dados Pessoais, como os Dados Pessoais são tratados, com quem são compartilhados (ressalvado o segredo comercial e industrial), dentre outros.</p>
      <p className="modal_body">12.2.	Para exercer os direitos previstos na LGPD disponibilizamos  canal eletrônico no site da ALESTA, que pode ser acessado neste <a href="https://privacyportal-br.onetrust.com/webform/853501c3-ce3d-4409-8cf6-537b6a1e703b/00c62b12-6a56-463d-82aa-0d69f84f37eb">link</a> e abaixo relacionamos o que você pode requerer através deste canal:</p>

      <Table 
        columns={columns} 
        dataSource={data} 
        size="small"
        pagination={false}
      />

      <p className="modal_body">12.3.	PESSOAS MAL-INTENCIONADAS PODEM USAR ESSE TIPO DE SOLICITAÇÃO PARA SE FAZER PASSAR POR VOCÊ E TENTAR OBTER SEUS DADOS PESSOAIS. DESTA FORMA, PARA ATENDER À SUA REQUISIÇÃO, A ALESTA SE UTILIZARÁ DE PROCEDIMENTOS PARA CONFIRMAR SE A SOLICITAÇÃO ESTÁ SENDO FEITA POR VOCÊ, PODENDO INCLUSIVE REQUERER O ENVIO DE OUTRAS INFORMAÇÕES E/OU DOCUMENTOS PARA RESPOSTA À SUA REQUISIÇÃO.</p>

      <p className="modal_body_title">13.	Decisões automatizadas</p>
      <p className="modal_body">13.1.	Algumas situações de tratamento de Dados Pessoais, como a análise de crédito, podem demandar a utilização de algoritmos designados para automatizar etapas dos trabalhos. A ALESTA se certifica de utilizar critérios claros para os seus processos de decisão automatizada.</p>
      <p className="modal_body">13.2.	Você pode solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de Dados Pessoais que afetem seus interesses.</p>
      <p className="modal_body">13.3.	Por motivos de segredo de negócio, proteção de informações confidenciais e preservação da concorrência, a ALESTA não informa a forma de funcionamento desses sistemas automatizados. Importante destacar que a ALESTA não garante um resultado diferente da primeira decisão, mas pode garantir que os modelos tecnológicos são consistentes e pautados em princípios legais e éticos.</p>

      <p className="modal_body_title">14.	Quem é o encarregado de dados da ALESTA?</p>
      <p className="modal_body">14.1.	O encarregado de dados é a pessoa física nomeada pela ALESTA para ser o contato entre a ALESTA, a Autoridade Nacional de Proteção de Dados e o Titular. Abaixo os dados do nosso encarregado de dados:</p>
      <p className="modal_body_item"><strong>Nome: Denise Ramos de Lima</strong></p>
      <p className="modal_body">Contato: para entrar em contato com o encarregado de dados ou registrar seus requerimentos e dúvidas, por favor acessar o nosso formulário eletrônico de proteção de dados que retornaremos à você preferencialmente por e-mail. </p>

      <p className="modal_body">14.2.	Para sua segurança e maior governança com relação aos requerimentos e dúvidas relacionados a LGPD, o encarregado de dados não está autorizado a responder questionamentos provenientes de outro canal que não o oficialmente definido pela ALESTA para essa finalidade.</p>

      <p className="modal_body_title">15.	Este Aviso de Privacidade pode ser atualizado?</p>
      <p className="modal_body">15.1.	No intuito de manter o usuário sempre atualizado, podemos alterar este Aviso de Privacidade a qualquer momento, ocasião em que a versão atualizada será disponibilizada em nosso site institucional.</p>
      <p className="modal_body">15.2.	O usuário deverá efetuar a leitura da nova versão do Aviso de Privacidade quando notificado, para que avalie se concorda com as alterações realizadas, e se deseja continuar utilizando e acessando os serviços e produtos da ALESTA.</p>
      <p className="modal_body">15.3.	Caso alguma disposição deste Aviso de Privacidade seja considerada ilegal ou ilegítima por autoridade competente da localidade em que o usuário se encontre, as demais condições permanecerão em pleno vigor e produção de efeitos.</p>
      <p className="modal_body">15.4.	O usuário reconhece que toda comunicação realizada por e-mail, SMS, aplicativos de comunicação instantânea ou qualquer outra forma eletrônica também é válida como prova documental, sendo eficazes e suficientes para a divulgação de qualquer assunto a que se refira aos serviços prestados pela ALESTA, bem como às condições de sua prestação ou a qualquer outro assunto nela abordado.</p>

      <p className="modal_body_title">16.	Glossário</p>
      <p className="modal_body">LGPD: Lei Geral de Proteção de Dados, a Lei nº 13,709/18. Estabelece regras sobre o tratamento de Dados Pessoais pelos agentes de tratamento. </p>
      <p className="modal_body">ANPD – Autoridade Nacional de Proteção de Dados. Autoridade de proteção de dados que terá como função zelar pelo cumprimento das regras da proteção de dados pessoais, editar normas e procedimentos conforme determinado na LGPD, , requisitar informações às empresas a respeito do tratamento dos dados, realizar registros e acompanhamento da reclamação dos titulares, fiscalizar e aplicar sanções aos controladores e operadores de dados.</p>
      <p className="modal_body">Permissão legal para tratamento de dados pessoais. A LGPD determina em quais hipóteses o Controlador pode tratar dados pessoais: Aqui citaremos àquelas mais relevantes para as finalidades de tratamento citadas nesse documento, lembrando que não existe hierarquia entre as bases legais, existe a mais adequada ao propósito do tratamento: (i) consentimento (quando é preciso autorização); (ii) cumprimento de obrigação legal ou regulatória (existe lei que autoriza ou determina o tratamento de dados); (iii) procedimentos preliminares de contratação ou execução de contrato (quando o tratamento é necessário para firmar o contrato com o titular de dados); (iv) interesse legítimo do contralador ou de terceiros (deve haver um equilíbrio entre o interesse legítimos da organização ou de terceiros e as expectativas e direitos e liberdades os titulares; (v) proteção ao crédito; (vi) exercício regular do direito em processo judicial, administrativo ou arbitral (para defesa de direitos do Controlador ou de terceiros); (viii) proteção da vida ou da incolumidade física do Titular de Dados ou de terceiro; (ix) garantia de prevenção à fraude nos processos de identificação e autenticação de cadastros em sistemas eletrônicos (apenas para Dados Pessoais Sensíveis).</p>
      <p className="modal_body">Controlador. É a quem compete a tomada de decisão sobre o tratamento dos Dados Pessoais.</p>
      <p className="modal_body">Operador.  É quem realiza o tratamento de Dados Pessoais em nome e sob as instruções do Controlador.</p>
      <p className="modal_body">Dados Pessoais. São informações relacionadas a uma pessoa natural identificada ou identificável. Por exemplo, o nome completo, um endereço de email, o nome dos seus pais e sua data de nascimento, todos são considerados Dados Pessoais.</p>
      <p className="modal_body">Dados Pessoais Sensíveis. são dados pessoais sobre origem racial, étnica, convicção religiosa, opinião política, filiação a sindicato, organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico. Para fins deste Aviso de Privacidade, sempre que estivermos falando de Dados Pessoais, estaremos fazendo referência tanto a Dados Pessoais quanto Dados Pessoais Sensíveis.</p>
      <p className="modal_body">Site a aplicativos. Se referem aos sites e aplicativos da ALESTA.</p>
      <p className="modal_body">Titular de Dados ou Titular. é a pessoa física identificada ou identificável, a quem se referem o objeto de tratamento dos Dados Pessoais. Portanto, a quem se destina este Aviso de Privacidade.</p>
      <p className="modal_body">Tratamento. toda e qualquer operação realizada com Dados Pessoais, o que inclui a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração. Assim, sempre que utilizamos o termo “tratamento” estamos nos referindo a qualquer uma dessas atividades.</p>
    </div>
  );
}