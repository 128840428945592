import { Button } from "@material-ui/core";
import { Modal } from "antd";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link as LinkRouter } from "react-router-dom";
import { Link } from "react-scroll";

import ModalPrivacyPolicyContent from "./PrivacyPolicyModalContent";
import { getUrl } from "../utils/getUrl";

export default function Header() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const url = getUrl();

  return (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark primary-bg shadow header">
      <div className="container pl-3 pr-3">
        <Helmet>
          <meta charSet="utf-8" />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            data-domain-script="65a6e80e-2c46-46e1-a780-140b847b984e"
          />
          <script type="text/javascript">
            {/* function OptanonWrapper() { }  */}
          </script>
        </Helmet>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <Link
          className="logo"
          to="about"
          spy={true}
          smooth={true}
          offset={-180}
          duration={500}
        >
          <img
            className="header-logo"
            src="/assets/images/logo.png"
            alt="logo Alesta"
          />
        </Link>

        <div className="button-mobile">
          <Button
            variant="contained"
            size="large"
            color="primary"
            href={`${url}/login?source=Alesta`}
            className="btn-simulate"
            target="_blank"
          >
            Meus contratos
          </Button>
        </div>

        <div
          className="collapse navbar-collapse custom-navbar"
          id="navbarTogglerDemo03"
        >
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                activeClass="link-style-selected"
                className="link-style"
                to="about-us"
                spy={true}
                smooth={true}
                offset={-180}
                duration={500}
              >
                Sobre nós
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="link-style-selected"
                className="link-style"
                to="benefits"
                spy={true}
                smooth={true}
                offset={-180}
                duration={500}
              >
                Vantagens
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="link-style-selected"
                className="link-style"
                to="how"
                spy={true}
                smooth={true}
                offset={-130}
                duration={500}
              >
                Como funciona
              </Link>
            </li>
            <li className="nav-item">
              <LinkRouter
                activeClass="link-style-selected"
                className="link-style"
                to="faq"
              >
                Dúvidas
              </LinkRouter>
            </li>
            <li className="nav-item">
              <Link
                activeClass="link-style-selected"
                className="link-style"
                to="contact"
                spy={true}
                smooth={true}
                offset={-180}
                duration={500}
              >
                Fale com a gente
              </Link>
            </li>
          </ul>
        </div>

        <div className="button-desktop">
          <Button
            variant="contained"
            size="large"
            color="primary"
            href={`${url}/login?source=Alesta`}
            className="btn-simulate"
            target="_blank"
          >
            Meus contratos
          </Button>
        </div>
      </div>

      <p className="tiny-text-header">
        Não cobramos taxa para análise de crédito
      </p>

      <div className="container_modal">
        <Modal
          title="Aviso de Privacidade"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          centered="true"
          style={{
            height: "calc(100vh - 200px)",
            top: 40,
          }}
          bodyStyle={{ overflowY: "scroll" }}
          width={1200}
        >
          <ModalPrivacyPolicyContent />
        </Modal>
      </div>
    </nav>
  );
}
