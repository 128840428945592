import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

const THEME = createTheme({
  typography: {
    fontFamily: `"Poppins", sans-serif;`,
  },
  palette: {
    primary: {
      main: "#C9DA2A",
    },
    secondary: {
      main: "#1EBCC5",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={THEME}>
      <Routes />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
