import { Modal, Space, Table } from "antd";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";


import "./FinancialStatements.css";
import { financialStatements } from "./data";

const FinancialStatements = ({
  modalVisibilityFinancialStatements,
  setModalVisibilityFinancialStatements,
}) => {

  const [selectedYear, setSelectedYear] = useState(financialStatements[0].year)

  const handleYearChange = (year) => {
    setSelectedYear(Number(year.target.value));
  };

  const currentYearData = financialStatements.find((item) => item.year === selectedYear);
  const selectedYearToString = selectedYear.toString()
  const columns = [
    {
      title: selectedYearToString,
      dataIndex: 'year',
      key: 'year',
      render: () => <span>Demonstrações Financeiras</span>
    },
    {
      title: `1S${selectedYearToString.slice(-2)}`,
      dataIndex: 'semester1',
      key: 'semester1',
      render: () => currentYearData.semester1 ? (
        <Space size="small">
          <Link
            to={currentYearData.semester1}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width={"22px"} src={require('../../assets/pdf.svg').default} alt="icone de arquivo pdf - demonstrativo financeiro"/>
          </Link>
        </Space>
      ) : null,
    },
    {
      title: `2S${selectedYearToString.slice(-2)}`,
      dataIndex: 'semester2',
      key: 'semester2',
      render: () => currentYearData.semester2 ? (
        <Space size="small">
          <Link
            to={currentYearData.semester2}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img width={"22px"} src={require('../../assets/pdf.svg').default} alt="icone de arquivo pdf - demonstrativo financeiro"/>
          </Link>
        </Space>
      ) : null,
    },
  ];

  return (
    <Modal
      title="Demonstrações Financeiras"
      centered
      zIndex={999999}
      open={modalVisibilityFinancialStatements}
      wrapClassName="partners-modal"
      footer={null}
      onOk={() => setModalVisibilityFinancialStatements(false)}
      onCancel={() => setModalVisibilityFinancialStatements(false)}
    >
      <div className="filter">
        <label htmlFor="input-filter" className="filter-label">
          Filtrar por:{" "}
        </label>
        <select
          className="filter-input"
          id="input-filtro"
          onChange={handleYearChange}
        >
          {financialStatements.map((item) => (
          <option key={item.year} value={item.year}>
            {item.year}
          </option>
        ))}
        </select>
      </div>
      <Table
        columns={columns}
        dataSource={[currentYearData]}
        pagination={false}
        size={"large"}
      />
    </Modal>
  );
};

export default FinancialStatements;
