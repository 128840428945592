import React from "react";
import "./HowItWorkSection.css";
import Button from "@material-ui/core/Button";
import { getUrl } from "../utils/getUrl";

export default function HowItWorkSection() {
  const url = getUrl(); 

  return (
    <div className="how-it-works-section" id="how">
      <div className="container pt-5">
        <div className="row pb-5">
          <div className="col-auto">
            <div className="row justify-content-center">
              <div className="section">
                <div className="line" />
                <h3>Como funciona tudo isso?</h3>
                <div className="line" />
              </div>
            </div>

            <h4>
              É bem <span className="highlight-green">simples</span>, e vamos
              explicar agora mesmo
            </h4>

            <p className="text-start">
              Se sua conta venceu e você não tem dinheiro, nada de ficar sem
              energia. A gente quita a conta para você, e com parcelas suaves
              que cabem no seu bolso, você paga de volta sem comprometer o seu
              orçamento.
            </p>

            <div className="row mt-5">
              <div className="col-lg-3 col-sm-12">
                <div className="row">
                  <div className="col-auto">
                    <p className="big-number">1.</p>
                  </div>
                  <div className="col">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                      <p className="big-number-text">
                        Clique abaixo{" "}
                        <span className="highlight-green">em simular</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12">
                <div className="row">
                  <div className="col-auto">
                    <p className="big-number">2.</p>
                  </div>
                  <div className="col">
                    <div className="d-flex h-100 justify-content-center align-items-center flex-column">
                      <p className="big-number-text">
                        Parcele em até{" "}
                        <span className="highlight-green"> 15x </span>
                      </p>
                      <p className="tiny-text m-0">
                        *Conforme análise de crédito
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12">
                <div className="row">
                  <div className="col-auto">
                    <p className="big-number">3.</p>
                  </div>
                  <div className="col">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                      <p className="big-number-text">
                        Assine o contrato{" "}
                        <span className="highlight-green">online</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-sm-12">
                <div className="row">
                  <div className="col-auto">
                    <p className="big-number">4.</p>
                  </div>
                  <div className="col">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                      <p className="big-number-text">
                        Pague mensalmente,{" "}
                        <span className="highlight-green">
                          {" "}
                          na conta de energia
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5 d-flex justify-content-center align-items-center flex-grow flex-column">
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className="mt-3"
                href={`${url}/register`}
                target="_blank"
              >
                Simule Agora
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
