import React from "react";

export default function AboutSection() {
  return (
    <div className="container mt-5 mb-5" id="about-us">
      <div className="row justify-content-center">
        <div className="col-auto">
          <div className="row justify-content-center">
            <div className="section">
              <div className="line" />
              <h3>Somos Alesta!</h3>
              <div className="line" />
            </div>
          </div>

          <h4>
            Ajuda <span className="highlight-accent">descomplicada</span> para
            um momento complicado
          </h4>
          <p className="mt-5">
            Dinheiro descomplicado, seguro e com condições atrativas para pagar
            contas de energia atrasadas. E o melhor, tudo isso sem você precisar
            sair de casa.
          </p>
          <p>
            Prometemos te ajudar, de forma fácil e rápida, a resolver seu
            problema com o pagamento da conta de energia, para que você não
            fique sem esse bem essencial.
          </p>

          <div className="container-benefits d-flex">
            <div className="content-benefits">
              <div className="circle secondary-bg">
                <img src="assets/images/lock.png" alt="" />
              </div>
                <p className="item-text">
                  Prático, rápido e <p className="item-text bold">seguro</p>
                </p>
            </div>

            <div className="content-benefits flex-grow-1">
              <div className="circle primary-bg">
                <img src="assets/images/bill.png" alt="" />
              </div>
                <p className="item-text">
                  Pagamento
                  <p className="item-text bold"> direto na conta de energia</p>
                </p>
            </div>

            <div className="content-benefits flex-grow-1">
              <div className="circle accent-bg">
                <img src="assets/images/clock.png" alt="" />
              </div>
                <p className="item-text bold">Sem burocracia</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
